
import { defineComponent, nextTick, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ApiService from "@/core/services/ApiService";
import {
  saveOrganization,
  saveRehab,
  saveUser,
  saveExpiresAt,
} from "@/core/services/JwtService";
import { getManager } from "@/store/modules/AuthModule";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "log-out",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(async () => {
      const userManager = getManager();
      try {
        await userManager.signoutRedirect({ "id_token_hint": store.getters.getIdToken });
      } finally {
        await store.dispatch(Actions.LOGOUT);
        router.push({ path: "open-id-login" });
      }
    });

    //Form submit function
    return {  };
  },
});
